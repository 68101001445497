import React from 'react'
import { Link, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

//fontawsome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

//components
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import StayLoop from "../../components/Home/stayloop";
import MoreArticles from "../../components/Home/morearticles";

const BlogSingle = (props) => {
  const { blog: post } = props.data
  const SEOdata = props.data.awardpagedata.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpagedata.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpagedata.edges[0].node.frontmatter?.Hreflang
  const { edges: morepost } = props.data.moreArticles
  return (
    <Layout>
       <Seo
        title={SEOdata ? SEOdata.seo_title : "What Makes a Website Effective 3 Reasons UI Design Improves Conversion Rates"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />



      {/* blog description section */}

      <section className="mt-60 lg:mt-20 pt-6-6 pb-35 lg:pt-51 lg:pb-59">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="hidden xl:block w-full lg:w-1/6"></div>
            <div className="w-full lg:w-4/5 xl:w-2/3">
              <h2 className="mb-3-3  lg:mb-1-3 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-term-primary">{post.frontmatter.title}</h2>
              <div className="blog-inner--cnt text-p4 lg:text-p2 font-worksans-normal text-term-primary"><h2>Effective Website: How UI Design Improves Conversion Rates</h2>
<p>Website owners around the world wonder the same question: what makes a website effective? There are  millions of sites today; some are very popular, others exist in  internet purgatory. How do you know if a site will perform
well?</p>
<p>In today's fast-paced industry, success is fluid and standard  formulas are constantly changing. However, some principles  remain the same regardless of current trends and that's  what you can build your site from.</p>
<p>Knowing the basics behind an effective website is not  difficult and these techniques will help your website go from  zero to hero in a second.</p>
<h3>What Makes a Website Effective? The Answer is  Simple</h3>
<p>At the end of this article, you will have the knowledge to  create a website that drives more traffic than you previously  thought possible. Continue reading below to get started.</p>
<ul>
<li>Can You Say SEO?</li>
</ul>
<p>You can’t say SEO enough times; it’s just that important for your UI design.</p>
<p>If you know how to use SEO effectively it will quickly become the best method of boosting your site's  performance. Keyword usage is one of the easiest ways to draw someone's attention on the internet.</p>
<p>People want to look up specific items and services and they search with search engines using phrases that we refer to as search terms. These search terms are also known as keywords, and the most successful businesses in your industry are using them–a lot.</p>
<p>Adding keywords to your website will give you a better chance of attracting the customers who need your products or services. Sprinkle them throughout your site (without overdoing it).</p>
<ul>
<li>More Articles</li>
</ul>
<p>You may have heard that people don’t read a lot on the internet with short attention spans but don’t let that stop you from adding articles. People want to trust the person they spend their money with. One of the best ways to show you know what you’re talking about is to create a blog and write articles.</p>
<p>Write articles related to your business to show people how much you know. It also makes people confident that you know what you’re talking about and they can learn from you.</p>
<p>As an added bonus, add the SEO keywords we mentioned earlier to your articles to increase the chance your site will show up on a search.</p>
<ul>
<li>Easy Use</li>
</ul>
<p>If you pay close attention you will notice the most successful websites are extremely easy to navigate? That's UI at its best.</p>
<p>People are often in a rush and don't have time for complicated sites. If it takes significant brainpower to navigate your website you’re likely the only one putting in the mental energy.</p>
<p>Always go with simplicity. This means buttons that are easy to notice, basic wording, and everything is easy to find on the page. Your customers will thank you and your pockets will too.</p>
<p>Let’s Get to Work</p>
<p>You now know what it takes to have an effective website and you know that you have the tools it takes to start. You just need a partner and what better place to look than DecodeUp?</p>
<p>We know how to navigate the world of big data and will do everything to ensure you succeed. We cover a range of services from big data services, web applications, and ERP to CRM, mobile app development, and so much more.</p>
<p>Want to learn more? Call us today and see what we can do together.</p></div>
            </div>
            <div className="w-full lg:w-1/6">
              <div className="w-full lg:w-3/4 text-left lg:text-right flex flex-wrap lg:flex-col justify-between">
                <div className="lg:mb-39">
                  <h5 className="lg:mb-1-1 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                    {post.frontmatter.blog_category}
                  </h5>
                  <p className="text-term-primary font-worksans-normal text-p4 lg:text-p3">
                    {post.frontmatter.date}
                  </p>
                </div>
                <div className="flex items-center lg:items-end lg:flex-col ">
                  <Link to={post.frontmatter.twitter} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary "
                      icon={faTwitter}
                    />
                  </Link>
                  <Link to={post.frontmatter.linkedin} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="mx-8 lg:mx-0 lg:my-5-1 font-awesome text-2xl text-term-primary "
                      icon={faLinkedinIn}
                    />
                  </Link>
                  <Link to={post.frontmatter.facebook} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary"
                      icon={faFacebookF}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* more articles section */}
      {morepost.length &&
        <section className="pb-50 lg:pb-1-04">
          <MoreArticles articles={morepost} />
        </section>
      }

      {/* stay in loop section */}
      <StayLoop />

    </Layout>

  )
}

export default BlogSingle

export const BlogPageQuery = graphql`
  query BlogPage7($id: String!) {
    blog: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        blog_category
        templateKey
        facebook
        linkedin
        twitter
        date(formatString: "DD MMM, YYYY")
        
      }
    }
    moreArticles: allMarkdownRemark(
      filter: {
              id: { ne: $id },
              frontmatter: { templateKey: { eq: "single-blog" } }
            }
      ) {
       edges {
         node {
           id
           fields {
             slug
           }
           frontmatter {
             title
             blog_category
             templateKey
             featured_image {              
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
             date(formatString: "DD MMM, YYYY")
           }
         }
       }
     }
     awardpagedata: allMarkdownRemark(filter: {id: {eq: $id}}) {
      edges {
        node {
          frontmatter {
            Hreflang {
              hreflang_1
              hreflang_3
              hreflang_2
              hreflang_4
            }
            Schema {
              aggregate_rating_schema
              breadcrumb_schema
              local_business_schema
              logo_schema
              organization_schema
              site_navigation_schema
              sitelinks_schema
              speakable_specification_schema
            }
            seo_metadata {
              facebook_url
              feature_image_height
              image_format
              img_width
              page_last_modified_time
              page_publish_date
              seo_description
              seo_keyword
              seo_title
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              twitter_image_alt_tag
              twitter_page_username
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
          }
        }
      }
    }
  }`